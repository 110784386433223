import $ from "jquery";
import AOS from "aos";
import Rellax from "rellax";
import axios from "axios";

const FORM_API_URL =
  "https://4s65j0i2zf.execute-api.eu-central-1.amazonaws.com/dev/merchantregistration";
 // "https://external.pob.com.tr/pobinstitution/api/v1/registration/merchant/registration";

const rellax = new Rellax(".rellax");

const credentials = {
  session: undefined,
  token: undefined,
};
const isNumericInput = (event) => {
  const key = event.keyCode;
  return (
    (key >= 48 && key <= 57) || // Allow number line
    (key >= 96 && key <= 105) // Allow number pad
  );
};

const isModifierKey = (event) => {
  const key = event.keyCode;
  return (
    event.shiftKey === true ||
    key === 35 ||
    key === 36 || // Allow Shift, Home, End
    key === 8 ||
    key === 9 ||
    key === 13 ||
    key === 46 || // Allow Backspace, Tab, Enter, Delete
    (key > 36 && key < 41) || // Allow left, up, right, down
    // Allow Ctrl/Command + A,C,V,X,Z
    ((event.ctrlKey === true || event.metaKey === true) &&
      (key === 65 || key === 67 || key === 86 || key === 88 || key === 90))
  );
};

const enforceFormat = (event) => {
  // Input must be of a valid number format or a modifier key, and not longer than ten digits
  if (!isNumericInput(event) && !isModifierKey(event)) {
    event.preventDefault();
  }
};

const formatToPhone = (event) => {
  if (isModifierKey(event)) {
    return;
  }

  // I am lazy and don't like to type things more than once
  const target = event.target;
  const input = event.target.value.replace(/\D/g, "").substring(0, 10); // First ten digits of input only
  const zip = input.substring(0, 3);
  const middle = input.substring(3, 6);
  const last = input.substring(6, 10);

  if (input.length > 6) {
    target.value = `(${zip}) ${middle} - ${last}`;
  } else if (input.length > 3) {
    target.value = `(${zip}) ${middle}`;
  } else if (input.length > 0) {
    target.value = `(${zip}`;
  }
};

// const inputElement = document.getElementById("input-phone");
// inputElement.addEventListener("keydown", enforceFormat);
// inputElement.addEventListener("keyup", formatToPhone);

AOS.init();

// Cookie Consent
$(window).on("load", () => {
  let cookies = document.cookie.split(";");
  if (cookies.find((c) => c.includes("cookie-consent"))) {
    // Cookie found
    let value = cookies
      .find((c) => c.includes("cookie-consent"))
      .replace("cookie-consent=");
    if (value === "true") {
      //apply analytics
    } else {
      //do nothing
    }
  } else {
    $("#cookie-consent").toggleClass("open");
  }
});
$("#cookie-accept").click(function (e) {
  e.preventDefault();
  document.cookie = "cookie-consent=true;";
  $("#cookie-consent").toggleClass("open");
});
$("#cookie-deny").click(function (e) {
  e.preventDefault();
  let d = new Date();
  d.setTime(d.getTime() + 1 * 24 * 60 * 60 * 1000);
  let exp = "expires=" + d.toUTCString();
  document.cookie = "cookie-consent=false; expires=" + exp + ";";
  $("#cookie-consent").toggleClass("open");
});
//

// Menu Button
$(".navbar-burger").click(function (e) {
  e.preventDefault();
  $(".navbar-burger").toggleClass("is-active");
  $("#" + $(".navbar-burger").attr("data-target")).toggleClass("is-active");
});

// FIX: CHANGE CHANNEL
// FIX: IDENTITY NUMBER
// Contact Form API variables

const query = {
  address: {
    cityCode: "xxxxx",
  },
  agreements: [
    {
      agreementCode: "POB_MENU_FIRMA_SOZLESMESI",
      agreementVersion: "1.1",
    },
  ],
  merchant: {
   // identityNumber: "",
    name: "xxxxx",
  },
  owner: {
    email: "xxxxx",
    ownerName: "xxxxx",
    ownerSurname: "xxxxx",
    phoneNumber: "xxxxx",
  },
};

//Slider
var slideIndex = 0;
carousel();

function carousel() {
  var i;
  var x = document.getElementsByClassName("slides");
  for (i = 0; i < x.length; i++) {
    x[i].style.display = "none";
  }
  slideIndex++;
  if (slideIndex > x.length) {
    slideIndex = 1;
  }
  x[slideIndex - 1].style.display = "block";
  setTimeout(carousel, 4000); // Change image every 3 seconds
}

// Contact Form
$("#contact-form").submit(function (e) {
  e.preventDefault();

  const _values = $("#contact-form").serializeArray();
  query.address.cityCode = _values.find((el) => el.name === "city").value;
  query.merchant.name = _values.find((el) => el.name === "company").value;
  query.owner.ownerName = _values
    .find((el) => el.name === "name")
    .value.substring(
      0,
      _values.find((el) => el.name === "name").value.lastIndexOf(" ")
    );
  query.owner.ownerSurname = _values
    .find((el) => el.name === "name")
    .value.substring(
      _values.find((el) => el.name === "name").value.lastIndexOf(" ")
    );
  query.owner.email = _values.find((el) => el.name === "email").value;
  query.owner.phoneNumber = _values
    .find((el) => el.name === "phone")
    .value.replace(/\D/g, "");

  let anyError = false;

  if (_values.find((el) => el.name === "name").value < 3) {
    $("#contact-form input[name='name']").addClass("is-danger");
    $("#contact-form input[name='name']").removeClass("is-success");
    anyError = true;
  } else {
    $("#contact-form input[name='name']").addClass("is-success");
    $("#contact-form input[name='name']").removeClass("is-danger");
  }
  if (_values.find((el) => el.name === "company").value < 1) {
    $("#contact-form input[name='company']").addClass("is-danger");
    $("#contact-form input[name='company']").removeClass("is-success");
    anyError = true;
  } else {
    $("#contact-form input[name='company']").addClass("is-success");
    $("#contact-form input[name='company']").removeClass("is-danger");
  }
  if (_values.find((el) => el.name === "city").value < 1) {
    $("#contact-form select[name='city']").parent().addClass("is-danger");
    $("#contact-form select[name='city']").parent().removeClass("is-success");
    anyError = true;
  } else {
    $("#contact-form select[name='city']").parent().addClass("is-success");
    $("#contact-form select[name='city']").parent().removeClass("is-danger");
  }
  if (
    !/^(\+90-|\+90|0)?\d{10}$/.test(
      _values.find((el) => el.name === "phone").value.replace(/\D/g, "")
    )
  ) {
    $("#contact-form input[name='phone']").addClass("is-danger");
    $("#contact-form input[name='phone']").removeClass("is-success");
    anyError = true;
  } else {
    $("#contact-form input[name='phone']").addClass("is-success");
    $("#contact-form input[name='phone']").removeClass("is-danger");
  }
  if (
    !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      _values.find((el) => el.name === "email").value
    )
  ) {
    $("#contact-form input[name='email']").addClass("is-danger");
    $("#contact-form input[name='email']").removeClass("is-success");
    anyError = true;
  } else {
    $("#contact-form input[name='email']").addClass("is-success");
    $("#contact-form input[name='email']").removeClass("is-danger");
  }
  if (!_values.find((el) => el.name === "terms")) {
    $("#contact-form input[name='terms']").parent().addClass("has-text-danger");
    $("#contact-form input[name='terms']")
      .parent()
      .removeClass("has-text-success");
    anyError = true;
  } else {
    $("#contact-form input[name='terms']")
      .parent()
      .addClass("has-text-success");
    $("#contact-form input[name='terms']")
      .parent()
      .removeClass("has-text-danger");
  }
  var interval = null;
  function makeRefreshForm() {
    clearInterval(interval);
    $("#contact-form")[0].reset();

    $("#contact-form input[name='name']").addClass("input");
    $("#contact-form input[name='name']").removeClass("is-danger");
    $("#contact-form input[name='name']").removeClass("is-success");

    $("#contact-form input[name='company']").addClass("input");
    $("#contact-form input[name='company']").removeClass("is-success");
    $("#contact-form input[name='company']").removeClass("is-danger");

    $("#contact-form select[name='city']").parent().addClass("select");
    $("#contact-form select[name='city']").parent().removeClass("is-success");
    $("#contact-form select[name='city']").parent().removeClass("is-danger");

    $("#contact-form input[name='phone']").addClass("input");
    $("#contact-form input[name='phone']").removeClass("is-success");
    $("#contact-form input[name='phone']").removeClass("is-danger");

    $("#contact-form input[name='email']").addClass("input");
    $("#contact-form input[name='email']").removeClass("is-success");
    $("#contact-form input[name='email']").removeClass("is-danger");

    $("#contact-form input[name='terms']").parent().addClass("checkbox");
    $("#contact-form input[name='terms']").parent().removeClass("has-text-success");
    $("#contact-form input[name='terms']").parent().removeClass("has-text-danger");

    $("#contact-form #contact-send-button").addClass(
      "button is-warning is-fullwidth"
    );
    $("#contact-form #contact-send-button").removeClass("is-success");
    $("#contact-form #contact-send-button").removeClass("is-danger");
    $("#contact-form #contact-send-button").text("Gönder");
  }
  if (anyError) {
    return;
  } else {
    $("#contact-form #contact-send-button").addClass("is-loading");
    // REQUEST HERE
    axios
      .post(
        FORM_API_URL,
        { merchant: query, agreements: [], channelCode:"POB" },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((_) => {
        interval = setInterval(makeRefreshForm, 2000);
        if (_.data.success) {
          $("#contact-form #contact-send-button").removeClass("is-loading");
          $("#contact-form #contact-send-button").removeClass("is-warning");
          $("#contact-form #contact-send-button").addClass("is-success");
          $("#contact-form #contact-send-button").text("Gönderildi!");
        } else {
          $("#contact-form #contact-send-button").removeClass("is-loading");
          $("#contact-form #contact-send-button").removeClass("is-warning");
          $("#contact-form #contact-send-button").addClass("is-danger");
          $("#contact-form #contact-send-button").text("Hata Oluştu!");
        }
      })
      .catch((_) => {
        $("#contact-form #contact-send-button").removeClass("is-loading");
        $("#contact-form #contact-send-button").removeClass("is-warning");
        $("#contact-form #contact-send-button").addClass("is-danger");
        $("#contact-form #contact-send-button").text("Hata Oluştu!");
      });
    // let req = $.post(
    //   API_CONTACT_URL,
    //   { customerRegistrationRequest: query },
    //   () => {
    //     $('#contact-form #contact-send-button').removeClass('is-loading')
    //     $('#contact-form #contact-send-button').removeClass('is-warning')
    //     $('#contact-form #contact-send-button').addClass('is-success')
    //     $('#contact-form #contact-send-button').text('Gönderildi!')
    //   }
    // ).fail(() => {
    //   $('#contact-form #contact-send-button').removeClass('is-loading')
    //   $('#contact-form #contact-send-button').removeClass('is-warning')
    //   $('#contact-form #contact-send-button').addClass('is-danger')
    //   $('#contact-form #contact-send-button').text('Hata Oluştu!')
    // })
  }
});
// End
